import React, { Component } from 'react'
import styled from 'styled-components'
import { Box, Title3, Large, colors } from 'js-components'
import { Section, WrapperBackground, WrapperSolid } from '../../components/Wrapper'
import { TestimonialGrid, TestimonialCard } from '../../components/TestimonialGrid'
import { Helmet } from 'react-helmet'
import { PageTitle, PageTitle3, PageTitle2, PageParagraph } from '../../components/PageTitle'
import BenefitsCompounds from '../images/benefitsCompounds.svg'
import BenefitsEasy from '../images/benefitsEasy.svg'
import BenefitsEngaging from '../images/benefitsEngaging.svg'
import BenefitsSecure from '../images/benefitsSecure.svg'
import Default from '../../components/Layouts/Default';
import { VideoFrame } from '../../components/VideoFrame';
import DeviceLineup from '../images/deviceLineup.png';

import LibertyRing from '../animations/libertyRing.json'
import DesktopSalesforce from '../images/desktopSalesforce.png';


var lottie

export default class BetterPhoneSystem extends Component {
  constructor(props) {
    super(props)
    this.animationIsAttached = false
  }

  componentDidMount() {
    lottie = require('lottie-web')
    this.attachAnimation()
  }

  attachAnimation() {
    if (this.animationContainer !== undefined && !this.animationIsAttached) {
      const animationProperties = {
        container: this.animationContainer,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: LibertyRing,
      }

      lottie.loadAnimation(animationProperties)
    }
  }

  render() {
    return (
      <Default>
        <Helmet>
          <title>Truly | Phone System</title>
        </Helmet>

        <WrapperBackground
          color={colors.trulyDark}
          background='headsetPerson3'
        >
          <Section flexDirection="row" py={['6rem', '7rem']} background={colors.trulyDark}>
            <Box width="1">
              <PageTitle2 as="h2" color={colors.accentFog} bold mb="1.5rem" center>
                Phone System
              </PageTitle2>
              <PageTitle as="p" color={colors.white} mb="1.5rem" center>
                Flawless Audio Quality.  The Best CRM Integrations.  Unmatched Support.
              </PageTitle>
            </Box>
          </Section>
        </WrapperBackground>

        <WrapperBackground center color={colors.trulyDark} background="managedByQ" gradientType="blueToGreen">
          <Section background={colors.trulyDark} flexDirection="column" maxWidth="100%">
            <Box>
                <PageTitle2 color={colors.white} mb="2rem" mt="2rem">
                Trusted By Category Defining Sales<br />& Customer Success Teams
              </PageTitle2>
            </Box>
            <TestimonialGrid>
              <TestimonialCard avatar="jackie" name="Jackie F" company="NewFront Insurance" quote="I love how you are able to listen to other colleagues calls while training. That live feature helped me significantly when I was training. I also like that you can see who is actively on a call."/>
              <TestimonialCard avatar="taylor" name="Taylor S" company="Cloud Kitchens" quote="Truly is great because it easily integrates with Salesforce to track my activity - both calls and texts. I also love the voicemail drop feature, as well as the mobile app that syncs flawlessly with my iPhone."/>
              <TestimonialCard avatar="billy" name="Billy B" company="Galvaninze" quote="I love how I can switch my number based on the purpose of my call and how easy Truly is to use. It's awesome I can listen to any of my colleagues phone calls at any time. It was a tremendous learning tool for me in my new job. The voicemail drop function is a game changer."/>
            </TestimonialGrid>
          </Section>
        </WrapperBackground>

        <WrapperSolid>
          <Section flexDirection="column" my={['1rem', '2rem', '5rem']}>
            <Box width="1" pb="2rem">
            </Box>
            <Box width={[1, 2 / 3]}>
              <PageTitle2 as="h2" color={colors.trulyBlue} bold mb="1.5rem" center>
                Work From Anywhere, <br/>On Any Device,<br/>
                With The Highest VOIP Quality
              </PageTitle2>
              <PageParagraph as="p" color={colors.trulyDark} mb="1.5rem">
                Our proprietry platform was engineered to address the thousands of VOIP issues of the most demanding users.
                <br />
                <br />
                Our apps are extraordinarily reliable and easy to use. We give you better call quality, faster connect times, blazingly responsive apps, realtime CRM sync, and the industry's best mobile experience.
                
              </PageParagraph>
            </Box>
            <VideoRow>

              <VideoFrame
                background="explainer"
                caption="Truly VOIP+ Quality"
                videoLink="https://player.vimeo.com/video/444206456?autoplay=1"
              />
              <VideoFrame
                background="twilio"
                caption="Standard VOIP Quality"
                videoLink="https://player.vimeo.com/video/444211496?autoplay=1"
              />
            </VideoRow>
          </Section>
        </WrapperSolid>

        <WrapperBackground color={colors.trulyDark} background="jet" gradientType="darkLessTransparent">
          <Section flexDirection="row" py={['6rem', '12rem']} background={colors.trulyDark}>
            <Box width={[0, 1 / 2]} />
            <Box width={[1, 1 / 2]}>
              <PageTitle2 as="h2" color={colors.accentFog} bold mb="1.5rem" center>
                The Last Voice Solution You'll Ever Buy
              </PageTitle2>
              <PageParagraph as="p" color={colors.white} mb="1.5rem" center>
                Truly is the only voice platform designed to serve the needs of all customer-facing teams, at companies
                of all sizes and geographies. Customizable integrations, powerful governance rules, and global presence
                ensure we'll be your partner for many years to come.
              </PageParagraph>
              <IconRow>
                <div
                  ref={animationDiv => {
                    this.iconAnimation = animationDiv;
                  }}
                />
              </IconRow>
            </Box>
          </Section>
        </WrapperBackground>

        <WrapperSolid>
          <Section flexDirection={['column', 'row']} my={['1rem', '2rem', '5rem']}>
            <Box width={[1 / 2, 1 / 2]} p="2rem">
              <WidthImg src={DesktopSalesforce} alt="Salesforce" />
            </Box>
            <Box width={[1, 1 / 2]}>
              <PageTitle2 as="h2" color={colors.trulyBlue} bold mb="1.5rem" center>
                The Ultimate CRM Integration
              </PageTitle2>
              <PageParagraph as="p" color={colors.trulyDark} mb="1.5rem" center>
                Lightweight, powerful and highly customizable, our integrations are designed to log more data, improve
                overall data accuracy, and minimize time to insight through Conversation Intelligence capabilities.
                <br />
                <br />
                We integrate with all major CRMs, Sales Engagement Platforms, Conversation Intelligence Platforms, and
                Business Intelligence Platforms. Have a custom CRM? No problem. Our APIs make it a breeze to integrate
                our systems.
              </PageParagraph>
            </Box>
          </Section>
        </WrapperSolid>

        <WrapperBackground color={colors.trulyDark} background="businessScene1" gradientType="darkLessTransparent">
          <Section flexDirection={['column', 'row']} py={['6rem', '7rem']} background={colors.trulyDark}>
            <Box width={[1, 1 / 2]}>
              <div
                ref={animationDiv => {
                  this.gaugeWebAnimation = animationDiv;
                }}
              />
            </Box>
            <Box width={[1, 1 / 2]}>
              <PageTitle2 as="h2" color={colors.accentFog} bold mb="1.5rem" center>
                Rated #1 In Support
              </PageTitle2>
              <PageParagraph as="p" color={colors.white} mb="1.5rem">
                No more waiting on hold.  No more escalations.  No more deflecting to your IT team.
                <br/><br/>
                With Truly, your reps can chat directly with our team of qualified IT pros, getting a response in under 2 mins, with a 98% first touch resolution rate.
                <br/><br/>
                This is why our customer support is consistently rated #1 on sites like G2Crowd
              </PageParagraph>
            </Box>
          </Section>
        </WrapperBackground>

      </Default>
    )
  }
}

const Values = styled.div`
  background-color: #222;
  font-family: 'Josefin Sans';
`

const Divider = styled.div`
  width: 100%;
  height: 1pt;
  background-color: rgba(255,255,255,0.3);
`
const WidthImg = styled.img`
  display: block;
  max-width: 100%;
  margin: 0 auto;
`

const VideoRow = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;

  @media (max-width: 1080px) {
    flex-direction: column;
  }
`;

const IconRow = styled.div`
  display: flex;
  justify-content: center;
`;